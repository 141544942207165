import axios from "axios";
import API from "../../components/Global"
import { globalStorage } from "../utils";
export const JsonList = async ({ url, param, page = 0 }) => {
    try {
        let pages;
        pages = '';
        if(page){
            pages = `?page=${page}`
        }
        let defaultParam = {
            organization_id: globalStorage.getItem('fin_user_data').organization_code,
            username: globalStorage.getItem('fin_user_data').username,
            signature: globalStorage.getItem('fin_user_data').signature
        }
        let merge = {...param,...defaultParam}
        const options = {
            url: `${API.LINK_API}${url}${pages}`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(merge)
        };
        const res = await axios(options).then(res => res.data);
        return res;
    } catch(error){
        return error.response.data;
    }
}

export const JsonCreateOrUpdate = async ({ url, param}) => {
    try {
        let defaultParam = {
            organization_id: globalStorage.getItem('fin_user_data').organization_code,
            username: globalStorage.getItem('fin_user_data').username,
            signature: globalStorage.getItem('fin_user_data').signature
        }
        let merge = {...param,...defaultParam}
        let options = {
            headers : {
                "Content-Type" : "application/json",
                "Accept" : "application/json",
            },
            method : 'POST',
            url : `${API.LINK_API}${url}`,
            data : JSON.stringify(merge)
        }
        const res = await axios(options).then(response => response.data);
        return res;
    } catch (error) {
        return error.response.data;
    }
}

export const JsonSearch = async ({ url, param }) => {
    try {
        let defaultParam = {
            organization_id: globalStorage.getItem('fin_user_data').organization_code,
            username: globalStorage.getItem('fin_user_data').username,
            signature: globalStorage.getItem('fin_user_data').signature
        }
        let merge = {...param,...defaultParam}
        let options = {
            headers : {
                "Content-Type" : "application/json",
                "Accept" : "application/json",
            },
            method : 'POST',
            url : `${API.LINK_API}${url}`,
            data : JSON.stringify(merge)
        }
        const res = await axios(options).then(response => response.data);
        return res;
    } catch (error) {
        return error.response.data;
    }
}

export const JsonLogin = async ({ url, param }) => {
    try {
        let options = {
            headers : {
                "Content-Type" : "application/json",
                "Accept" : "application/json",
            },
            method : 'POST',
            url : `${API.LINK_API}${url}`,
            data : JSON.stringify(param)
        }
        const res = await axios(options).then(response => response.data);
        return res;
    } catch (error) {
        return error.response.data;
    }
}

export const JsonDelete = async ({ url, param }) => {
    try {
        let defaultParam = {
            organization_id: globalStorage.getItem('fin_user_data').organization_code,
            username: globalStorage.getItem('fin_user_data').username,
            signature: globalStorage.getItem('fin_user_data').signature
        }
        let merge = {...param,...defaultParam}
        let options = {
            headers : {
                "Content-Type" : "application/json",
                "Accept" : "application/json",
            },
            method : 'POST',
            url : `${API.LINK_API}${url}`,
            data : JSON.stringify(merge)
        }
        const res = await axios(options).then(response => response.data);
        return res;
    } catch (error) {
        return error.response.data;
    }
}

export const DownloadExcel = async ({ url, param }) => {
    try {
        let defaultParam = {
            organization_id: globalStorage.getItem('fin_user_data').organization_code,
            username: globalStorage.getItem('fin_user_data').username,
            signature: globalStorage.getItem('fin_user_data').signature
        }
        let merge = {...param,...defaultParam}
        const res = await axios({
            headers : {
                "Content-Type" : "application/json",
                "Accept" : "application/json",
            },
            responseType:'blob',
            method : 'POST',
            url : `${API.LINK_API}${url}`,
            data : JSON.stringify(merge)
        });
        return res.data;
    } catch (error) {
        let message = {}
        if(error.response.status === 404){
            message = {'response_code': 404, 'response_desc': 'Data tidak ditemukan'}
        } else if(error.response.status === 422){
            message = {'response_code': 422, 'response_desc': 'Permintaan tidak dapat diproses'}
        } else {
            message = {'response_code': 500, 'response_desc': 'Maaf terjadi kesalahan internal'}
        }
        return message;
    }
}

export const FormDataCreateUpdate = async ({ url, formData }) => {
    try {
        const res = await axios({
            headers : {
                "Content-Type" : "multipart/form-data",
            },
            method : 'POST',
            url : `${API.LINK_API}${url}`,
            data : formData
        });
        return res.data;
    } catch (error) {
        return error.response.data
    }
}

export const JsonMenu = async (param = '') => {
    let send;
    send = '';
    if(param !== ''){
        send = `/${btoa(JSON.stringify({ dt : param }))}`
    }
    try {
        const res = await axios({
            method : 'GET',
            url : `${API.LINK_API}configuration/menu${send}`,
        });
        return res.data;
    } catch (error) {
        return error.response.data;
    }
}

export const JsonFile = async (param = '') => {
    try {
        const res = await axios({
            method : 'GET',
            url : `${param}`,
        });
        return res.data;
    } catch (error) {
        return error.response.data;
    }
}

export const JsonCreateOrUpdateCustomParam = async ({ url, param }) => {
    try {
        let options = {
            headers : {
                "Content-Type" : "application/json",
                "Accept" : "application/json",
            },
            method : 'POST',
            url : `${API.LINK_API}${url}`,
            data : JSON.stringify(param)
        }
        const res = await axios(options).then(response => response.data);
        return res;
    } catch (error) {
        return error.response.data;
    }
}