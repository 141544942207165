import { create } from "zustand"
import { JsonList } from "../api"
import { globalStorage } from "../utils"

function storeAlert(set){
    return {
        success: false,
        message: ``,
        error: false
    }
}

function storeParams(set) {
    return {
        params : false,
        setParams : (val) => set((prev) => ({...prev, ...val})) 
    }
}

function storeOption(set){
    return {
        option: [
            { label:'Bertambah', value:'+' },
            { label:'Berkurang', value:'-' }
        ]
    }
}

function storeAccess(set){
    return {
        code: '',
        name: '',
        option: []
    }
}

function storeCoa(set){
    return { option: []}
}

function storeDropzone(set){
    return {
        files : [],
        error : [],
        oldFiles : [],
        clearState: () => set({
            file: [],
            error: [],
            oldFiles: []
        }) 
    }
}

function storeGroup(set){
    return {
        code: ``,
        updateCode: (param) => set(() => ({code: param})),
        loading: false,
        data: [],
        page: 1,
        limit: 10,
        totalData: 0,
        option: []
    }
}

function storeData(set){
    return {
        code: ``,
        loading: false,
        data: [],
        header: [],
        page: 1,
        limit: 10,
        totalData: 0,
        option: [],
        selectedCoa: [], // filter selected for profit loss to general ledger
        startDate:'', // filter selected for profit loss to general ledger
        endDate:'', // filter selected for profit loss to general ledger
        filterSelected: false, // filter selected for profit loss to general ledger
        setHeader: (val) => set((state) => ({...state, header: val})),
        setSelected: (val) => set((state) => ({...state, ...val})), // filter selected for profit loss to general ledger
        clearSelected: () => set((state) => ({...state, selectedCoa: [], startDate:'', endDate:'', filterSelected: false})) // filter selected for profit loss to general ledger
    }
}

function storeCategoryCoa(set){
    return {
        id: '',
        name: '',
        options: [],
        optionsTable: [],
        fetchOptions: async (url) => {
            let param = {
                request:"List Category COA",
                organization_id: globalStorage.getItem('fin_user_data').organization_code,
                username: globalStorage.getItem('fin_user_data').username,
                signature: globalStorage.getItem('fin_user_data').signature
            };
            JsonList({url,param})
                .then((response) => {
                    let data = response.response_data;
                    let newData = data?.flatMap((dt) => {
                        return { label: dt.name, value: dt.id.toString() } ?? []
                    }, [data])
                    let newData2 = data?.flatMap((dt) => {
                        return { text: dt.name, value: dt.id.toString() } ?? []
                    }, [data])
                    set({ options : newData})
                    set({ optionsTable : newData2})
                })
        } 
    }
}

function storeSideBar (set) {
    return {
        active : true
    }
}

const useStoreAlert = create(storeAlert)
const useStoreCategoryCoa = create(storeCategoryCoa)
const useStoreGroup = create(storeGroup)
const useStoreSubGroup = create(storeData)
const useStoreDetailGroup = create(storeData)
const useStoreAccount = create(storeData)
const useStoreJournal = create(storeData)
const useStoreMigration = create(storeData)
const useStoreProfile = create(storeAccess)
const useStoreUnit = create(storeData)
const useStoreUser = create(storeData)
const useStoreExpense = create(storeData)
const useStoreCoaAssets = create(storeCoa)
const useStoreCoaPassive = create(storeCoa)
const useStoreMutation = create(storeOption)
const useStoreRevenue = create(storeData)
const useStoreSideBar = create(storeSideBar)
const useStoreDropzone = create(storeDropzone)
const useStoreParams = create(storeParams)

export {
    useStoreAccount,
    useStoreCategoryCoa,
    useStoreGroup,
    useStoreDetailGroup,
    useStoreSubGroup,
    useStoreAlert,
    useStoreJournal,
    useStoreMigration,
    useStoreProfile,
    useStoreUnit,
    useStoreUser,
    useStoreExpense,
    useStoreCoaAssets,
    useStoreCoaPassive,
    useStoreMutation,
    useStoreRevenue,
    useStoreSideBar,
    useStoreDropzone,
    useStoreParams
}