import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStoreAlert } from "./src/services/state";
import Cookies from "js-cookie";
import { globalStorage } from "./src/services/utils";

const loading = (
  <Box className="flex h-screen justify-center items-center">
    <CircularProgress />
  </Box>
);
const Layout = lazy(() => import("./src/layout/mainLayout"));
const Auth = React.lazy(() => import("./src/pages/auth"));
const Login = lazy(() => import("./src/pages/login"));
const ChooseUnit = lazy(() => import("./src/pages/unit"));
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    mainGray: createColor("#525C77"),
    secondaryGray: createColor("#888"),
    mainBlue: createColor("#7380EC"),
    mainRed: createColor("#EF4444"),
  },
});
const queryClient = new QueryClient();
const GetAccessToken = () => Cookies.get("fin_access_token");
const GetRefreshToken = () => Cookies.get("fin_refresh_token");
const IsAuthenticated = () => !!GetAccessToken();
const RequireAuth = ({ children }) => {
  let auth = IsAuthenticated();
  let location = useLocation();

  if (!auth) {
    Cookies.remove("fin_access_token");
    Cookies.remove("fin_unit_data");
    globalStorage.removeItem("fin_user_data");
    globalStorage.removeItem("fin_menu_data");
    globalStorage.removeItem("fin_units");
    Cookies.remove("fin_refresh_token");
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (GetRefreshToken()) {
    try {
      const tokens = Cookies.get("fin_access_token");
      const expires = (tokens.expires_in || 30 * 60) * 1000;
      const inOneHour = new Date(new Date().getTime() + expires);
      Cookies.set("fin_access_token", tokens, { expires: inOneHour });
      Cookies.set("fin_refresh_token", "refresh_token");

      return children;
    } catch (error) {
      Cookies.remove("fin_access_token");
      Cookies.remove("fin_unit_data");
      globalStorage.removeItem("fin_user_data");
      globalStorage.removeItem("fin_menu_data");
      globalStorage.removeItem("fin_units");
      Cookies.remove("fin_refresh_token");
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }

  return children;
};
function App() {
  const { error, success, message } = useStoreAlert();
  const alertSuccess = () => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    setTimeout(() => useStoreAlert.setState({ success: false, message: "" }), 3000);
  };
  const alertError = () => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    setTimeout(() => useStoreAlert.setState({ error: false, message: "" }), 3000);
  };
  useEffect(() => {
    if (success) {
      alertSuccess();
    }
    if (error) {
      alertError();
    }
  }, [error, success, alertSuccess, alertError]);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router basename="/">
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/auth/:id" name="Auth Page" element={<Auth />} />
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route
                exact
                path="/pilih-unit"
                name="Pilih Unit"
                element={
                  <RequireAuth>
                    <ChooseUnit />
                  </RequireAuth>
                }
              />
              <Route
                path="*"
                name="Home"
                element={
                  <RequireAuth>
                    <Layout />
                  </RequireAuth>
                }
              />
            </Routes>
          </Suspense>
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
