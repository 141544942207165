import { EncryptStorage } from 'encrypt-storage';
import { useEffect, useState } from 'react';
import { useStoreAlert } from '../state';

const useDebounce = (value, milliSeconds) => {
    // function for handle search when user stop typing
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
    const handler = setTimeout(() => {
        setDebouncedValue(value);
    }, milliSeconds);

    return () => {
        clearTimeout(handler);
    };
    }, [value, milliSeconds]);

    return debouncedValue;
};

const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const findNode = (param, currentNode) => {
    for (const node of currentNode) {
        if (node.action === param) return node;
        if (node.children) {
            const child = findNode(param, node.children);
            if (child) return child;
        }
    }
}

const fileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

const convertToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
})

const handleErrorFromApi = ({response ,alert = false}) => {
    let responseApi = response;
    let message;
    message = '';
    if(!responseApi?.response_desc){ // kondisi jika response status dari api 200 atau tidak mendapatkan response_desc dari api
        let status = response?.response_code?.toString();
        
        if(!status){ // kondisi jika tidak terdapat response_code dari api
            message = 'Oops! Terjadi kesalahan';
            if(alert){
                useStoreAlert.setState({error:true, message})
            }
            return true
        }

        let desc = response?.response_desc;
        if(parseInt(status.charAt(0)) !== 2){ // kondisi jika response_code dari api ternyata tidak 200 walaupun response status 200
            
            if(response?.response_data){ // konsisi terdapat response data
                return false;
            } 

            if(alert){
                useStoreAlert.setState({error:true, message:desc})
            }
            return true
        } 
    }

    message = responseApi?.response_desc; // kondisi jika response dari api tidak 200
    if(message){
        let status = response?.response_code?.toString();
        if(responseApi?.response_data){ // konsisi terdapat response data,
            return false;
        }

        if(parseInt(status.charAt(0)) !== 2){ // kondisi jika response_code dari api ternyata tidak 200 walaupun response status 200
            
            if(response?.response_data){ // konsisi terdapat response data
                return false;
            } 

            if(alert){
                useStoreAlert.setState({error:true, message})
            }
            return true
        }
    }
    
    return false
}

const fetchEncryptId = (id) => {
    try {
        const params = id?.toString() || ''
        const code = JSON.parse(atob(params))
        return code;
    } catch(e) {
        return false
    }
}

const globalStorage = new EncryptStorage("global_params", {
    storageType     : 'localStorage',
    encAlgorithm    : 'Rabbit'
})

export {
    useDebounce,
    handleErrorFromApi,
    findNode,
    numberWithCommas,
    globalStorage,
    fileSize,
    convertToBase64,
    fetchEncryptId
}